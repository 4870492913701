:root {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    --color-accent: #1D3FA5;
    --border-radius: 8px;
    --background: #F6F4EF;
}

* {
    box-sizing: border-box;
}

body {
    /* display: grid;
    grid-template-columns:  repeat(3, minmax(300px, 500px)); */
    min-width: 300px;
    max-width: 400px;
    margin: 0;
    background-color: var(--background);
}

body > * {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    min-height: 100vh;
}

h1 {
    font-weight: 500;
}

input,
select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: var(--border-radius);
    padding: 8px 8px;
    margin-bottom: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
}

input:focus {
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.4);
}

/* The animation code */
@keyframes example {
    from {border: 1px solid rgba(0, 0, 0, 0.4);}
    to {border: 1px solid rgba(0, 0, 0, 0.1);}
  }
  
  /* The element to apply the animation to */
.animate {
    border: 1px solid rgba(0, 0, 0, 0.4);
    animation-name: example;
    animation-duration: 400ms;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

.error {
    border: 1px solid rgba(223, 10, 10, 0.411) !important;
}


button {
    background: white;
    border-radius: var(--border-radius);
    padding: 8px 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 2px rgba(0,0,0,0.1);
    font-weight: 600;
    color: rgba(0, 0, 0, 0.74);
    
}
button:hover {
    /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
    cursor: pointer;
}

button:focus {
    box-shadow: 0 0px 0px rgba(0,0,0,0.1);
}

#food-summary {
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding: 12px 18px;
    max-width: 399px;
    width: 100%;
    background-color: #F6F4EF;
    display: flex;
    justify-content: space-between;
}
 #total-calories {
    margin: 0px;
    padding: 0px;
}

#food-list {
    min-height: 100px;
    padding-bottom: 40px;
}

/*
#food-summary {
    margin-top: auto;
} */

#boxes {
    margin-top: 12px;
    line-height: 0;
}

#food__add  * {
    width: 100%;
}

.dot {
    width: 2px;
    height: 4px;
    background-color: red;
    margin: 1px;
    display: inline-block;
}


#food-list {
    margin: 12px 0;
}


.food-row {
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center;
    grid-gap: 4px;
    /* margin-bottom: 4px; */
    cursor: pointer;
}

div {
    user-select: none;
}

.food-row .delete {
    position: relative;
    right: 0px;
    margin: 4px 0;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: transparent;
    box-shadow: none;
    font-size: 16px;
    padding-top: 6px;
}


.food-row__details {
    opacity: 0.4;
    display: inline-block;
}

.consumed .food-row__details {
    opacity: 1;
    color: var(--color-accent);
}

#total-calories {
    color: var(--color-accent);
}


#food__details {
    margin-bottom: 12px;
    font-size: 12px;
}

#food__details input {
    font-size: 12px;
    padding: 4px;
}

#food__details > div {
    display: grid;
    grid-template-columns: 1fr 88px;
    justify-content: space-between;
}

#food__details input {
    text-align: right;
}

h3 {
    margin-bottom: 8px;
    text-transform: capitalize;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

p {
    margin-top: 4px;
}

p.faded {
    color: rgba(0, 0, 0, 0.6);
}

button:disabled,
button[disabled]{
    box-shadow: none;
    color: rgba(0, 0, 0, 0.4);
    cursor: default;
    background-color: rgb(250, 250, 250);
}

input.large {
    font-size: 16px;
    padding: 12px 12px;
    padding-bottom: 14px;
}

button.naked {
    /* border: none; */
    background: none;
    box-shadow: none;
}

.faded {
    color: rgba(0, 0, 0, 0.4)
}

